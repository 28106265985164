export default [
  {
    id: 'jf3va',
    name: 'Claire',
    fullName: 'Claire Marnette',
    subtitle: 'Fondatrice, directrice artistique & experte réseaux sociaux',
    emailRole: 'Fondatrice, directrice artistique',
    description: (
      <>
        Forte de près de 8 ans d’expérience dans le monde de l’influence et armée d’un master en droit, Claire a appris
        au fil des années à manier l’art du story-telling et de la création de contenu au travers des différentes
        campagnes d’influence réalisées pour ses réseaux sociaux et clients en marque blanche. Elle a saisi le rôle
        central de la présence digitale pour une marque ainsi que le rôle prépondérant que supporte l’image pour vendre
        un produit.
      </>
    ),
    descriptionFun: (
      <>
        Claire a commencé sa carrière dans le digital en élevant des chevaux virtuels sur Internet. Persévérante, elle
        s’est rapidement hissée sur les podiums des meilleures écuries virtuelles. De caractère curieux elle développe
        au quotidien des compétences diverses, telles que la chasse au pigeon au bureau, ou encore l’art de cramer les
        omelettes, c’est pourquoi il vaut mieux faire confiance à Deliveroo quand c’est son tour de passer au fourneau
        et la laisser dans son monde digital.
      </>
    ),
    image: {
      fun: { image: '/team/claire-fun.png', alt: 'Claire avec une bulle de chewing-gum' },
      work: { image: '/team/claire-work.png', alt: 'Claire avec une ampoule sur le tête' }
    }
  },
  {
    id: 'gfjj3',
    name: 'Clara',
    fullName: 'Clara Simon',
    subtitle: 'Project manager & stratège en communication',
    emailRole: 'Project manager & stratège en communication',
    description: (
      <>
        Grâce à son expérience dans différents domaines de la communication tels que l’influence, le brand content, mais
        également les relations presse, elle présente une vision 360 alliant médias digitaux et médias classiques.
        Caractérisée par sa conception stratégique mais créative et convaincue par les pouvoirs du brand marketing, elle
        permet aux entreprises de créer une relation forte avec leur audience sur les médias sociaux.
      </>
    ),
    descriptionFun: (
      <>
        Si vous additionnez du rose, des assiettes de spaghettis à la putanesca, de bons moments avec ses copines en
        city-trip, une grande dose de glitter, beaucoup de bavardages, un peu (trop) d'organisation, des tonnes de fous
        rires, un litre de moments en famille et enfin quelques kilos de chocolat, vous obtiendrez une Clara. N’hésitez
        pas à la contacter pour animer vos soirées !
      </>
    ),
    image: {
      fun: { image: '/team/clara-fun.png', alt: 'Clara avec des citrons à la place des yeux' },
      work: { image: '/team/clara-work.png', alt: 'Clara au téléphone' }
    }
  },
  {
    id: 'vdf3a',
    name: 'Tess',
    fullName: 'Tess Meurice',
    subtitle: 'Photographe & Graphiste',
    emailRole: 'Photographe & Graphiste',
    description: (
      <>
        Graphiste de formation et photographe autodidacte depuis près de 5 ans, Tess allie ces deux disciplines
        fortement liées dans le but de sublimer un produit, une marque ou une idée. La maîtrise de ces deux compétences
        lui permet d’effectuer un travail complet et de garder une cohérence sur l’entièreté de la réalisation d’un
        projet.
      </>
    ),
    descriptionFun: (
      <>
        Je voulais commencer ma description en racontant que j’aime les couchers de soleil, les crêpes et apprendre
        toutes sortes de faits insolites. Mais saviez-vous que les loutres de mer dormaient en se donnant la main pour
        ne pas dériver et ainsi éviter de s’éloigner les unes des autres pendant leur sommeil?
      </>
    ),
    image: {
      fun: { image: '/team/tess-fun.png', alt: 'Tess qui écoute de la musique' },
      work: { image: '/team/tess-work.png', alt: 'Tess, appareil photo à la main' }
    }
  },
  {
    id: 'vdf3az',
    name: 'Florence',
    fullName: 'Florence Bonsangue',
    subtitle: 'Social Media Manager',
    emailRole: 'Social Media Manager',
    description: (
      <>
        Digitale Native et journaliste de formation, Florence a 5 ans d'expérience dans la Communication Digitale, dont
        4 ans en agence. Spécialisée dans la stratégie de contenu et le copywriting, elle sait jouer avec les mots et
        les images pour faire passer le bon message, au bon moment, à la bonne personne.
      </>
    ),
    descriptionFun: (
      <>
        Puis je vous présente Polo, nouvelle mascotte d'Atelier Milky, mon lévrier whippet qui m'accompagne partout. Il
        adore les grandes balades dans les bois et les longues soirées devant le feu. D'origine italienne, moi j'adore
        les fleurs, les spaghetti bolo et les spritz. On se retrouve en terrasse ?
      </>
    ),
    image: {
      fun: { image: '/team/florence-fun.png', alt: 'Florence avec son chien Polo' },
      work: {
        image: '/team/florence-work.png',
        alt: "Florence qui tient en main son ordinateur d'un côté et son téléphone de l'autre"
      }
    }
  },
  {
    id: 'vdf3av',
    name: 'Julie',
    fullName: 'Julie Goris',
    subtitle: 'Assistante Graphiste',
    emailRole: 'Assistante Graphiste',
    description: (
      <>
        Illustratrice autodidacte, créatif publicitaire et graphiste, Julie est une touche à tout. Armée d'une bonne
        dose d'imagination, de techniques et d'une réflexion logique, elle vient en aide sur nos projets à n'importe
        quel moment.
      </>
    ),
    descriptionFun: (
      <>
        Je ne voulais pas me présenter de manière formelle donc pourquoi pas décrire ma soirée parfaite pour mieux me
        connaître ? Netflix and chill avec un gros pyjama, mes chiens, mes peluches et un bon verre de rosé à la main.
        N'oublions pas la nourriture !!! Voilà, vous savez tout de moi en un seul paragraphe.
      </>
    ),
    image: {
      fun: { image: '/team/julie-fun.png', alt: 'Julie qui tient son doudou dans les bras' },
      work: { image: '/team/julie-work.png', alt: 'Julie avec sa tablette graphique' }
    }
  },
  {
    id: 'kfder3',
    name: 'Pol',
    fullName: 'Pol Marnette',
    subtitle: 'Expert SEO & Développeur web',
    emailRole: 'Expert SEO & Développeur web',
    description: (
      <>
        Passionné depuis toujours par l'informatique, Pol a acquis au fil des années un grand nombre de compétences
        telles que le développement web et l’expertise SEO . Réel autodidacte, c’est sa passion qui anime son travail.
        Reactjs, Html et CSS, n’ont aucun secret pour lui !
      </>
    ),
    descriptionFun: (
      <>
        J’ai été obligé d’écrire ces lignes et je n’ai vraiment pas d’inspiration. Déjà écrire l’autre description ça
        m’a pris plus de temps que de développer tout le site et on m’en demande une deuxième... Que dire pour avoir la
        taille minimum qu'on m'a demandée... Encore un petit peu... Hop, on est bon !
      </>
    ),
    image: {
      fun: { image: '/team/pol-fun.png', alt: 'Pol qui joue aux jeux vidéos' },
      work: { image: '/team/pol-work.png', alt: 'Pol avec son ordinateur' }
    }
  }
]

export const pagemeta = {
  indexIntro: (
    <>
      <p>Atelier Milky est composé d’une équipe de jeunes créatifs dynamiques, passionnés par le monde du digital.</p>
      <p>
        Nos profils diversifiés nous permettent de pousser nos idées créatives chaque jour plus loin pour obtenir les
        meilleurs résultats pour nos clients.
      </p>
    </>
  ),
  intro: (
    <>
      Atelier Milky est composé d’une équipe de jeunes créatifs dynamiques, passionnés par le monde du digital. Nos
      profils diversifiés nous permettent de pousser nos idées créatives chaque jour plus loin pour obtenir les
      meilleurs résultats pour nos clients !
    </>
  )
}
