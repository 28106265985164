import Link from 'next/link'
import Image from 'next/image'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
dayjs.locale('fr')

export default function articleCard({ slug, featuredImage, title, excerpt }) {
  return (
    <Link href={`/blog/${slug}`}>
      <a>
        <article>
          {featuredImage && (
            <div className="relative w-full overflow-hidden" style={{ paddingTop: '100%' }}>
              <Image
                src={`${featuredImage.node.mediaItemUrl}`}
                alt={`${featuredImage.node.altText}`}
                layout="fill"
                className="absolute z-10 object-cover"
              />
              <div className="absolute z-0 inset-0 w-full h-full bg-gray-300 object-cover animate-pulse"></div>
            </div>
          )}
          {/* <div className="mt-2 text-gray-500 text-xs">
                      <span className="font-bold tracking-wider uppercase">
                        {thisArticle?.categories.nodes[0].name}
                      </span>
                      <span> ― </span>
                      <span>{dayjs(thisArticle.date).format('D MMM YYYY')}</span>
                    </div> */}
          <div className="mt-6">
            <h2 className="text-xl font-bold">{title}</h2>
            <div className="text-justify" dangerouslySetInnerHTML={{ __html: excerpt }} />
          </div>
        </article>
      </a>
    </Link>
  )
}
