// import { useState, useEffect } from 'react'
import Image from 'next/image'

export default function Teamimage({ work, fun, withFun = false, isFun = false, setFun, className = '' }) {
  // const [showFun, setShowFun] = useState(true)

  // useEffect(() => {
  //   setTimeout(() => setShowFun(false), 1000)
  // }, [])

  // return (
  //   <div
  //     onClick={() => setShowFun((prev) => !prev)}
  //     onMouseEnter={() => setShowFun(true)}
  //     onMouseLeave={() => setShowFun(false)}>
  //     {!showFun ? (
  //       <Image layout="fill" className={`${className} absolute top-0 object-cover `} src={work.image} alt={work.alt} />
  //     ) : (
  //       <Image layout="fill" className={`${className} absolute top-0 object-cover`} src={fun.image} alt={fun.image} />
  //     )}
  //   </div>
  // )

  if (withFun)
    return (
      <div onMouseEnter={() => setFun(true)} onMouseLeave={() => setFun(false)}>
        {isFun ? (
          <Image layout="fill" className={`${className} absolute top-0 object-cover`} src={fun.image} alt={fun.image} />
        ) : (
          <Image
            layout="fill"
            className={`${className} absolute top-0 object-cover `}
            src={work.image}
            alt={work.alt}
          />
        )}
      </div>
    )

  return (
    <div className="group">
      <div className="block group-hover:hidden group-active:hidden">
        <Image layout="fill" className={`${className} absolute top-0 object-cover `} src={work.image} alt={work.alt} />
      </div>
      <div className="group-hover:block group-active:block hidden">
        <Image layout="fill" className={`${className} absolute top-0 object-cover`} src={fun.image} alt={fun.image} />
      </div>
    </div>
  )
}
