import Link from 'next/link'

import Layout from 'components/layout'
import Cta from 'components/cta'
import Sectiontitle from './shared/title'
import Teamimage from 'components/equipe/teamImage'

import equipeData, { pagemeta } from 'data/equipe'

export default function equipe() {
  return (
    <Layout>
      <div className="block md:hidden">
        <Sectiontitle>
          <span>Notre</span>
          <br />
          <span className="pl-24">équipe</span>
        </Sectiontitle>
      </div>
      <div className="grid gap-12 grid-cols-1 mt-12 md:gap-24 md:grid-cols-2 md:mt-0">
        <div className="relative">
          <div
            aria-hidden="true"
            className="w-[500px] h-[250px] absolute -z-10 -left-64 -top-12 hidden bg-orange-200 rounded-b-full md:block"></div>

          <div
            aria-hidden="true"
            className="w-[200px] h-[400px] absolute -z-10 -bottom-24 -right-52 hidden bg-transparent border-4 border-blue-200 rounded-r-full md:block"></div>

          <p
            className="absolute -z-10 -right-56 bottom-48 hidden text-yellow font-serif text-7xl font-medium opacity-50 transform rotate-90 md:block"
            aria-hidden="true">
            coooooool
          </p>

          <div className="grid gap-4 grid-cols-2">
            {equipeData
              .map((thisMember) => ({ ...thisMember.image, name: thisMember.name, id: thisMember.id }))
              .map((thisImage) => (
                <Link href={`/equipe#${thisImage.name}`} key={thisImage.id}>
                  <a className="">
                    <div className="group pt-[142%] relative w-full overflow-hidden">
                      <Teamimage work={thisImage.work} fun={thisImage.fun} />
                    </div>
                  </a>
                </Link>
              ))}
          </div>
        </div>

        <div className="md:pl-24">
          <div className="hidden md:block">
            <Sectiontitle>
              <span>Notre</span>
              <br />
              <span className="pl-24">équipe</span>
            </Sectiontitle>
          </div>

          <div className="text-jusitfy text-lg space-y-4 md:mt-12">{pagemeta.indexIntro}</div>

          <Cta href="/equipe" className="mt-12" type="sm">
            Découvrir notre équipe
          </Cta>
        </div>
      </div>
    </Layout>
  )
}
