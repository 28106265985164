import Layout from 'components/layout'
import Sectiontitle from './shared/title'
import Articlecard from 'components/article/card'

export default function blog({ articles }) {
  return (
    <Layout className="relative">
      <Sectiontitle>Le blog</Sectiontitle>

      <div className="grid gap-8 grid-cols-1 mt-24 md:grid-cols-3">
        {articles.map((thisArticle) => (
          <Articlecard
            key={thisArticle.id}
            slug={thisArticle.slug}
            featuredImage={thisArticle.featuredImage}
            title={thisArticle.title}
            excerpt={thisArticle.excerpt}
          />
        ))}
      </div>

      <div
        aria-hidden="true"
        className="absolute -z-10 -right-100 -top-36 hidden w-112 h-112 bg-orange rounded-full md:block"></div>
      <div
        className="absolute -right-36 top-120 hidden text-yellow font-serif text-7xl font-medium opacity-50 transform rotate-90 md:block"
        style={{ transformOrigin: '100% 0%' }}
        aria-hidden="true">
        ressources
      </div>
    </Layout>
  )
}
