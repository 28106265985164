import { getHomePost } from '../lib/api'

import Seo from 'components/seo'
import Layout from 'components/layout'
import Navbar from 'components/navbar'
import Services from 'components/index/services'
import Portfolio from 'components/index/portfolio'
import Cta from 'components/cta'
import Equipe from 'components/index/equipe'
import Blog from 'components/index/blog'
import Footer from 'components/footer'

export default function home({ homePosts }) {
  return (
    <>
      <Seo
        title="Atelier Milky - Agence créative à Bruxelles"
        description="Agence vous accompagnant dans le développement de votre  entreprise  sur les réseaux sociaux au travers de stratégie digitale, création de contenu, et identité de marque"
        canonical="/"
        showBrandInTitle={false}
      />
      <div className="overflow-hidden">
        <Navbar />
        <section className="mt-12 md:mt-24">
          <Layout className="relative">
            <div className="flex flex-col justify-center">
              <div className="px-4 text-justify font-serif text-2xl leading-tight text-gray-500 md:px-12 md:text-3.5xl">
                <h1 className="inline">Atelier Milky</h1>
                <p className="inline">
                  {' '}
                  est une agence de communication à la croisée des chemins. Alliant stratégie digitale, image de marque,
                  direction artistique et production de contenu de qualité, nous vous accompagnons pour faire de votre
                  présence digitale un succès !
                </p>
                <p className="mt-8">
                  A l'affût des dernières tendances digitales, nous proposons une approche marketing toujours dans l'ère
                  du temps et en adéquation avec la réalité du terrain.
                </p>
              </div>
              <Cta href="/contact" className="mt-16" type="sm">
                Travailler avec nous
              </Cta>
            </div>

            <div
              aria-hidden="true"
              className="absolute -bottom-52 -left-96 -z-10 hidden h-96 w-96 rounded-full bg-orange md:block"></div>
            <div
              className="absolute -bottom-64 -left-72 hidden rotate-90 transform font-serif text-7xl font-medium text-yellow opacity-50 md:block"
              aria-hidden="true">
              personnalisés
            </div>

            <div
              aria-hidden="true"
              className="absolute -right-120 -top-10 -z-10 hidden h-100 w-200 rounded-t-full border-4 border-blue bg-transparent md:block"></div>
            <div
              className="absolute -right-16 top-112 hidden rotate-90 transform font-serif text-7xl font-medium text-yellow opacity-50 md:block"
              style={{ transformOrigin: '100% 0%' }}
              aria-hidden="true">
              créativité
            </div>
          </Layout>
        </section>
        <section className="relative mt-24 md:mt-40">
          <Services />
        </section>
        {/* <section className="relative mt-24 md:mt-52">
        <div
          aria-hidden="true"
          className="-z-10 absolute -top-8 right-24 hidden bg-transparent border-4 border-blue rounded-r-full md:block"
          style={{ width: 400, height: 800 }}></div>
        <div
          className="absolute -left-32 bottom-24 hidden text-yellow-100 font-serif text-9xl font-medium transform rotate-90 md:block"
          aria-hidden="true">
          moderne
        </div>

        <Portfolio />
      </section> */}

        <section className="relative mt-24 md:mt-40">
          <Equipe />
        </section>
        <section className="relative mt-24 md:mt-48">
          <Blog articles={homePosts?.nodes} />
        </section>

        <Cta href="/contact" className="mt-24">
          Contactez-nous
        </Cta>

        <div className="mt-40">
          <Footer />
        </div>
      </div>
    </>
  )
}

export async function getStaticProps() {
  const homePosts = await getHomePost()
  return {
    props: { homePosts },
    revalidate: 1800 // 30 min
  }
}
