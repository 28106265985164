import Link from 'next/link'
import Layout from 'components/layout'
import Sectiontitle from './shared/title'
import Cta from 'components/cta'

import { servicesHomeData } from 'data/services'

export default function services() {
  return (
    <Layout>
      <div className="">
        <Sectiontitle>Services</Sectiontitle>

        <ul className="mt-12 grid grid-cols-1 gap-4 md:grid-cols-4">
          {servicesHomeData.map((thisService) => (
            <li key={thisService.id}>
              <div className="flex items-center space-x-4 md:items-start md:space-x-4">
                <div>
                  <Link href={`/services/${thisService.slug}`}>
                    <a>
                      <div className="inline-flex h-10 w-10 items-center justify-center rounded-full border border-orange text-orange">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 34 34"
                          className="h-6 w-6 md:h-6 md:w-6"
                          stroke="currentColor">
                          <path strokeWidth={1.5} d="M32 17H17M17 17H2M17 17V2M17 17V32.5" />
                        </svg>
                      </div>
                    </a>
                  </Link>
                </div>
                <div>
                  <Link href={`/services/${thisService.slug}`}>
                    <a>
                      <h3 className="text-xl font-bold">{thisService.name}</h3>
                    </a>
                  </Link>
                  <div className="mt-2 hidden md:block">{thisService.descriptionIndex}</div>
                </div>
              </div>
              <div className="mt-4 block md:hidden">{thisService.descriptionIndex}</div>
            </li>
          ))}
        </ul>
        <Cta href="/services" className="mt-16" type="sm">
          Découvrir nos services
        </Cta>
      </div>
    </Layout>
  )
}
